var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"9"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Support")])])],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"","opacity":"0.6","spinner-variant":"primary"}},[_c('b-card',{attrs:{"title":"Contact Form"}},[(_vm.status)?_c('b-alert',{staticClass:"m-1",attrs:{"variant":_vm.variant,"show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.status))])])]):_vm._e(),_c('p',{staticClass:"m-1"},[_vm._v(" Please complete the form below to contact us. You can also email us on: "),_c('a',{attrs:{"href":"mailto:support@leechall.io"}},[_vm._v("support@leechall.io")]),_vm._v(" | Telegram: "),_c('a',{attrs:{"href":"https://t.me/ndt258","target":"_blank"}},[_vm._v("@ndt258")]),_vm._v(" | Channel: "),_c('a',{attrs:{"href":"https://t.me/leechallcom","target":"_blank"}},[_vm._v("https://t.me/leechallcom")])]),_c('validation-observer',{ref:"contactForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Subject","label-for":"subject","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subject","placeholder":"Subject","state":errors.length > 0 ? false : null,"required":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Message","label-for":"message","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"message","state":errors.length > 0 ? false : null,"placeholder":"Message","rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('vue-recaptcha',{ref:"reCaptcha",staticClass:"mt-1",attrs:{"sitekey":_vm.sitekey,"size":"invisible"},on:{"verify":_vm.onContact,"expired":_vm.onExpired}}),_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }